import {
  faXTwitter,
  faYoutube,
  faInstagram,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons';
import SocialLinksGroup from '../SocialLinksGroup/SocialLinksGroup';
import React, { useState } from 'react';
import { Col, Row, Nav, Navbar, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SOCIAL_MEDIA_URLS } from '../../constants';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import { openExternalLinkInPopupWindow } from '../../utils/CommonUtils';
import styles from './Footer.module.scss';
import { navigateToPromoter } from '../../utils/NavigationUtils';
import logo from '../../assets/logo-dark.svg';
import sectigoImg from '../../assets/sectigo_trust.png';
import { useModal } from '../../context/useModal';

/**
 * Renders the footer section of the application.
 *
 * @return {JSX.Element} The rendered footer component.
 */
const Footer = () => {
  const { openModal } = useModal();
  const showPrivacyModal = () => {
    openModal(<PrivacyPolicy />);
  };
  const showTermsModal = () => {
    openModal(<TermsAndConditions />);
  };
  /**
   * Opens a social media link in a popup window.
   *
   * @param {string} url - The URL of the social media link.
   * @param {string} name - The name of the social media platform.
   * @return {void} This function does not return anything.
   */
  const openSocialMediaInPopup = (url, name) => {
    openExternalLinkInPopupWindow(url, name, null);
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.group1}>
          <div className={styles.groupContainer}>
            <Link to="" className={styles.footerLink}>
              <span>Home</span>
            </Link>
            <Link to="about" className={styles.footerLink}>
              <span>About Us</span>
            </Link>
            <Link to="events" className={styles.footerLink}>
              <span>Live Events</span>
            </Link>
            <Link to="home" className={styles.footerLink} disabled>
              <span>Streams</span>
            </Link>
            <Link to="faq" className={styles.footerLink}>
              <span>FAQ</span>
            </Link>
          </div>
        </div>
        <div className={styles.socials}>
          <h3>Social Media</h3>
          <p>Follow us on Social media</p>
          <div className={styles.linksContainer}>
            <SocialLinksGroup />
          </div>
          <div className={styles.logoContainer}>
            <img src={logo} alt="Eventlinx Logo" className={styles.logo} />
          </div>
          <p className={styles.termsP}>
            Please read our{' '}
            <button onClick={() => showTermsModal()}>Privacy Policy</button> and{' '}
            <button onClick={() => showPrivacyModal()}>Terms of Use</button>
          </p>
          <p className={styles.copyright}>
            Copyright 2024 EventLinx Inc. All rights reserved.
          </p>
          <p className={styles.copyright1}>Copyright 2024 EventLinx Inc.</p>
          <p className={styles.copyright2}>All rights reserved.</p>
          <div className={styles.sectigoContainer}>
            <img
              src={sectigoImg}
              alt="Sectigo Logo"
              className={styles.sectigo}
            />
          </div>
        </div>
        <div className={styles.group2}>
          <div className={styles.groupContainer}>
            <Link to="contact-us" className={styles.footerLink}>
              <span>Support/Contact Us</span>
            </Link>
            <Link to="schedule-demo" className={styles.footerLink}>
              <span>Schedule a Demo</span>
            </Link>
            <a
              href={`${process.env.REACT_APP_PROMOTER_SITE}/login`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.footerLink}
            >
              <span>Promoter Login</span>
            </a>
            <Link to="login" className={styles.footerLink}>
              <span>Patron Login</span>
            </Link>
            <Link to="contact-us" className={styles.footerLink} disabled>
              <span>Become an Affiliate</span>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
