import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import {
  validateEmail,
  validatePassword,
} from '../../utils/authValidationUtils';
import { sendResetPasswordEmail } from '../../utils/APIUtils';
import styles from './ForgotPassword.module.scss';
import FormInput from '../../components/FormInput/FormInput';
import CustomButton from '../../components/CustomButton/CustomButton';

/**
 * React function component that exports and renders HTML elements for the forgot password modal.
 *
 * @return {JSX.Element} The JSX element that renders the forgot password modal.
 */
export default function ForgotPassword() {
  // State hook to set the captcha response.
  // const [captchaResponse, setCaptchaResponsed] = useRecaptcha();

  // State hook to set an email.
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // State hook to manage errors.
  const [errors, setErrors] = useState({});
  const [resetLinkSent, setResetLinkSent] = useState(false);

  /**
   * Handles the form submission event.
   *
   * @param {Event} e - The form submission event.
   * @return {void} This function does not return anything.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    const emailError = validateEmail(email);
    const passwordError = validatePassword(password, confirmPassword);

    if (emailError) {
      errors.email = emailError;
    }

    if (passwordError) {
      errors.password = passwordError;
    }

    if (Object.keys(errors).length === 0) {
      submitForm();
    }

    setErrors(errors);
  };

  /**
   * Submits the form data.
   *
   * @return {void} This function does not return anything.
   */
  const submitForm = async () => {
    try {
      if (resetLinkSent) {
      } else {
        console.log('send email to: ' + email);
        const sendLinkResponse = await sendResetPasswordEmail(email);
        console.log(sendLinkResponse);
        setResetLinkSent(true);
      }
    } catch (err) {
      console.log(err.response);
      setResetLinkSent(false);
      setErrors({ api: err.response.data.error.message });
    }
  };

  return (
    <div className={styles.forgotPassword}>
      <div className={styles.forgotPasswordContainer}>
        <div className={styles.head}>
          <h1 className={styles.registerTitle}>
            {resetLinkSent ? 'Check your email' : 'Forgot Password'}{' '}
          </h1>
          <div className={styles.msgContainer}>
            <p>
              {resetLinkSent
                ? 'We sent you a password reset link'
                : "No worries, we'll send you reset instructions"}
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          {!resetLinkSent && (
            <div className={styles.formGroup}>
              <FormInput
                label="Email"
                type="email"
                value={email}
                onInputChange={setEmail}
                placeholder="Enter your email address"
                error={errors.email}
              />

              <CustomButton type="submit" title="Submit" />
            </div>
          )}

          {resetLinkSent && (
            <div className={styles.formGroup}>
              <p>Didn't recieve the email?</p>
              <CustomButton
                type="button"
                clickHandler={() => setResetLinkSent(false)}
                title="Click to resend"
              />
            </div>
          )}
        </form>
        <div className={styles.linkContainer}>
          <Link to="/login" className={styles.link}>
            <FontAwesomeIcon icon={faArrowLeft} />
            <span>Back to Login</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
