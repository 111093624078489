import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ImagePicker from '../../../components/ImagePicker/ImagePicker';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import CustomButton from '../../../components/CustomButton/CustomButton';
import styles from './ContactInfo.module.scss';
import { updateContactInfo } from '../../../utils/APIUtils';
import validateContactInfo from './contactInfoValidator';
import { useAuth } from '../../../context/useAuth';
import FormInput from '../../../components/FormInput/FormInput';
import AddressFormSection from '../../../components/AddressFormSection/AddressFormSection';
import { useModal } from '../../../context/useModal';
import ContactInfoSavedModal from '../../../components/ContactInfoSavedModal/ContactInfoSavedModal';
import { ADDRESS_TYPES } from '../../../constants/index';
import { formatAddress } from '../../../utils/CommonUtils';

// Empty address
const emptyAddress = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  province: '',
  postalCode: '',
  country: '',
};

/**
 * The ContactInfo function is the main component for handling the contact
 * information of the user.
 *
 * @return {JSX.Element} The JSX element representing the ContactInfo component.
 */
export default function ContactInfo() {
  const navigate = useNavigate();
  const auth = useAuth();
  const { openModal, closeModal } = useModal();

  // refs
  const inputRef = useRef(null);
  const profileImageRefDiv = useRef(null);
  // Make a copy of the user object to make the changes on it before saving
  const [userDetails, setUserDetails] = useState(structuredClone(auth.user));
  const [wasEdited, setWasEdited] = useState(false);
  const [isBillingEqualToShipping, setIsBillingEqualToShipping] =
    useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (auth.user && !wasEdited) {
      setUserDetails(structuredClone(auth.user));
    }
  }, [auth.user]);

  useEffect(() => {
    if (isBillingEqualToShipping) {
      setUserDetails((prev) => ({
        ...prev,
        shippingAddress: prev.billingAddress,
      }));
    }
  }, [isBillingEqualToShipping]);

  /**
   * Validates the contact information.
   *
   * @return {boolean} Returns true if the contact information is valid, false otherwise.
   */
  const validate = () => {
    // TODO: Still need to validate the addresses, update the validator method
    const error = validateContactInfo(userDetails);
    if (Object.entries(error).length > 0) {
      console.log('errors: ', error);

      setErrors(error);
      return false;
    }
    return true;
  };

  /**
   * Validates the contact information.
   *
   * @return {boolean} Returns true if the contact information is valid, false otherwise.
   * @function
   */
  const handleChangeBillingAddress = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      billingAddress: { ...prevUserDetails.billingAddress, [name]: value },
    }));
    if (isBillingEqualToShipping) {
      setUserDetails((prevUserDetails) => {
        return {
          ...prevUserDetails,
          shippingAddress: {
            ...prevUserDetails.billingAddress,
          },
        };
      });
    }
  };

  /**
   * Handles the change event for the shipping address fields.
   *
   * @param {ChangeEvent<HTMLInputElement>} e - The change event.
   */
  const handleChangeShippingAddress = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      shippingAddress: {
        shippingAddress: { ...prevUserDetails.shippingAddress, [name]: value },
      },
    }));
  };

  // Handle address autocomplete
  const setAutocompleteValue = (value, addressType) => {
    const address = formatAddress(value, addressType);

    if (addressType === ADDRESS_TYPES.BILLING) {
      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        billingAddress: address,
        shippingAddress: isBillingEqualToShipping
          ? address
          : prevUserDetails.shippingAddress,
      }));
    } else if (addressType === ADDRESS_TYPES.SHIPPING) {
      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        shippingAddress: address,
      }));
    }
  };

  // Edits the address depending on the address type
  const onAddressEdit = (type) => {
    if (type === ADDRESS_TYPES.BILLING) {
      setUserDetails((prev) => ({ ...prev, billingAddress: emptyAddress }));
    } else if (type === ADDRESS_TYPES.SHIPPING) {
      setUserDetails((prev) => ({ ...prev, shippingAddress: emptyAddress }));
    }
  };

  // Handles the form submission event.
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userDetails) {
      // Create user details object
      if (validate()) {
        // Send the user details to the api
        delete userDetails.additionalInfo;
        delete userDetails.email;
        delete userDetails.emailVerified;
        delete userDetails.dateOfBirth;
        delete userDetails.authMethod;
        delete userDetails.id;

        const res = await updateContactInfo(userDetails);
        if (res.status === 200) {
          //show success message
          openModal(<ContactInfoSavedModal />, 'contactInfoSaved');
          setTimeout(() => {
            closeModal();
          }, 2000);
        } else {
          // show error
          setErrors((prebErrors) => {
            return {
              ...prebErrors,
              updating: 'Something went wrong, please try again',
            };
          });
        }
      }
    } else {
      navigate('/login');
    }
  };

  const handleUserDetailsChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevUserDetails) => {
      return {
        ...prevUserDetails,
        [name]: value,
      };
    });
  };

  return (
    <div className={styles.contactInfo}>
      <div className={styles.contactInfoContainer}>
        <div className={styles.titleContainer}>
          <h2>Contact Information</h2>
          <p>Update your account details anytime</p>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <section className={styles.imgContainer}>
            <h4>Profile picture</h4>
            <div className={styles.tempImage}></div>
            {/* <ImagePicker image={image} onImageChange={handleImageChange} /> */}
          </section>
          <section className={styles.basicInfo}>
            <h3>Basic information</h3>
            <div className={styles.nameContainer}>
              <FormInput
                ariaLabel="first name"
                label="First Name"
                placeholder=""
                value={userDetails.firstName}
                name="firstName"
                labelColor={'semiDark'}
                isRequired={true}
                inputChangeHandler={handleUserDetailsChange}
                onClearError={setErrors}
                error={errors.firstName || errors.updating || null}
              />
              <FormInput
                ariaLabel="last name"
                label="Last Name"
                placeholder=""
                value={userDetails.lastName}
                name="lastName"
                labelColor={'semiDark'}
                isRequired={true}
                inputChangeHandler={handleUserDetailsChange}
                onClearError={setErrors}
                error={errors.lastName || null}
              />
            </div>
            <div className={styles.phoneContainer}>
              <FormInput
                ariaLabel="phone"
                label="Phone"
                placeholder=""
                value={userDetails.phoneNumber}
                name="phoneNumber"
                labelColor={'semiDark'}
                inputChangeHandler={handleUserDetailsChange}
                onClearError={setErrors}
                error={errors.phoneNumber || null}
              />
            </div>
          </section>
          <div className={styles.addressContainer}>
            <AddressFormSection
              sectionTitle="Billing Address"
              address={userDetails.billingAddress}
              onAddressChange={handleChangeBillingAddress}
              errors={errors}
              setErrors={setErrors}
              onAddressEdit={onAddressEdit}
              setAutocompleteValue={setAutocompleteValue}
              addressType={ADDRESS_TYPES.BILLING}
              setWasEdited={setWasEdited}
            />
            <div className={styles.shippingAddressCheckbox}>
              <CustomCheckbox
                onChangeChecked={() =>
                  setIsBillingEqualToShipping(!isBillingEqualToShipping)
                }
                checked={isBillingEqualToShipping}
                variant="semiDark"
              />
              <label>Shipping address is same as the billing address</label>
            </div>

            {!isBillingEqualToShipping && (
              <AddressFormSection
                sectionTitle="Shipping Address"
                address={userDetails.shippingAddress}
                onAddressChange={handleChangeShippingAddress}
                errors={errors}
                setErrors={setErrors}
                onAddressEdit={onAddressEdit}
                setAutocompleteValue={setAutocompleteValue}
                addressType={ADDRESS_TYPES.SHIPPING}
                setWasEdited={setWasEdited}
              />
            )}
            <div className={styles.btnContainer}>
              <CustomButton
                type="submit"
                className={styles.submitButton}
                title="Save"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
