import React, { useLayoutEffect, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './LoginInfo.module.scss';
import FormInput from '../../../components/FormInput/FormInput';
import { useAuth } from '../../../context/useAuth';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { useModal } from '../../../context/useModal';
import ChangeCredentialsPopupContent from '../../../components/ChangeCredentialsPopupContent/ChangeCredentialsPopupContent';
import {
  validateEmail,
  validatePassword,
} from '../../../utils/authValidationUtils';
import blueCheck from '../../../assets/blue-check.svg';
import { updateLoginInfo, logoutUser } from '../../../utils/APIUtils';
import LoginInfoSavedModal from '../../../components/LoginInfoSaved/LoginInfoSavedModal';
/**
 * LoginInfo is a functional component that renders the login information for the user to update password and email.
 *
 * @return {JSX.Element} The LoginInfo component that renders the login information form.
 */
export default function LoginInfo() {
  const auth = useAuth();
  const { email } = auth.user;
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const [credentialsValidated, setCredentialsValidated] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [newEmail, setNewEmail] = useState(email || '');
  const [errors, setErrors] = useState({});
  const [sucess, setSuccess] = useState(false);

  // Enable form inputs only if credentials are validated
  const isInputDisabled = !credentialsValidated;
  const showCredentialsModal = () => {
    openModal(
      <ChangeCredentialsPopupContent
        onCredentialsValidation={setCredentialsValidated}
      />,
      'white'
    );
  };

  const showUpdateSuccessModal = (message) => {
    openModal(<LoginInfoSavedModal message={message} />, 'loginInfoSaved');
  };

  useEffect(() => {
    if (!auth.user) {
      navigate('/login');
    }
  }, [auth.user]);

  useEffect(() => {
    if (sucess) {
      setCredentialsValidated(false);
      setNewPassword('');
      setNewPasswordConfirm('');
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }
  }, [sucess]);

  const submitChanges = async () => {
    console.log('newEmail', newEmail);
    const emailValidationError = validateEmail(newEmail);
    if (emailValidationError) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          email: emailValidationError,
        };
      });
    }
    const passwordValidationErrors = validatePassword(newPassword);
    if (Object.keys(passwordValidationErrors).length > 0) {
      if (passwordValidationErrors.password) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            ...passwordValidationErrors,
          };
        });
      }
    }
    if (Object.keys(errors).length === 0) {
      // Check if the new email is different from the old one
      let requestData;
      let popupMsg =
        'Login information updated successfully. Please login again.';
      if (newEmail !== email) {
        requestData = { email: newEmail, password: newPassword };
        popupMsg =
          'Email updated successfully. Please verify your new email to login again.';
      } else {
        requestData = { password: newPassword };
      }
      console.log('requestData: ', requestData);
      // API call
      const response = await updateLoginInfo(requestData);
      console.log('updateLoginInfo response', response);
      if (response.status === 200) {
        showUpdateSuccessModal(popupMsg);
        setTimeout(() => {
          closeModal();
          logoutUser();
          auth.logout();
          navigate('/login');
        }, 3000);
      } else {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            email: response.message,
          };
        });
      }
    }
  };

  return (
    <div className={styles.loginInfo}>
      <div className={styles.loginInfoContainer}>
        <h2 className="fw-bold mt-3 mb-0">Login Information</h2>
        <p className={styles.desc}>
          Update your password and email information anytime.
        </p>
        <div className={styles.inputContainer}>
          <FormInput
            label="Email Address"
            type="email"
            name="email"
            value={newEmail}
            labelColor="semiDark"
            isDisabled={isInputDisabled}
            onInputChange={setNewEmail}
            error={errors.email}
            onClearError={() => setErrors({})}
          />
          <FormInput
            label="Password"
            type="password"
            name="password"
            value={credentialsValidated ? newPassword : 'itDoesNotMatter'}
            labelColor="semiDark"
            isDisabled={isInputDisabled}
            onInputChange={setNewPassword}
            isRequired={credentialsValidated}
            minLength={8}
            error={errors.password}
            onClearError={() => setErrors({})}
          />
          {credentialsValidated && (
            <FormInput
              label="Confirm New Password"
              type="password"
              name="confirmPassword"
              value={newPasswordConfirm}
              labelColor="semiDark"
              onInputChange={setNewPasswordConfirm}
              isRequired={true}
              minLength={8}
              error={errors.confirmPassword}
              onClearError={() => setErrors({})}
            />
          )}
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.btnsContainer}>
            <CustomButton
              title={credentialsValidated ? 'Update' : 'update login Info'}
              clickHandler={
                credentialsValidated ? submitChanges : showCredentialsModal
              }
            />

            {credentialsValidated && (
              <CustomButton
                title="Discard"
                colorScheme="outlineWhite"
                clickHandler={showCredentialsModal}
              />
            )}
          </div>
          <p className={styles.successMsg}>
            <img src={blueCheck} alt="success" />
            Your password has been updated.
          </p>
        </div>
      </div>

      <div className={styles.divider}></div>
    </div>
  );
}
