import NotAuthenticatedUserHeader from '../NotAuthenticatedUserHeader/NotAuthenticatedUserHeader';
import AuthenticatedUserHeader from '../AuthenticatedUserHeader/AuthenticatedUserHeader';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import { useAuth } from '../../context/useAuth';
import { useState, useEffect } from 'react';
import styles from './AuthNLngContainer.module.scss';
import * as storageUtils from '../../utils/LocalStorageUtils';
import { checkCookieExpiration } from '../../utils/APIUtils';
import { useNavigate } from 'react-router-dom';

export default function AuthNLngContainer() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(
    auth.user ? true : false
  );

  // When the user changes, check if the user is logged in..
  useEffect(() => {
    console.log(
      'User changed on AuthNLngContainer, and user is => ',
      auth.user
    );

    let intervalId;
    if (auth.user) {
      console.log(
        'checking expiration on AuthNLngContainer triggered. User logged in'
      );
      const expirationDate = storageUtils.getItem('expirationDate');

      if (expirationDate && expirationDate > Date.now()) {
        console.log('Expiration date found and is ahead of now');

        // Check the expiration date of the token each 5 minutes
        // checkTokenExpiration automatically calls the renewSession function if necessary
        intervalId = setInterval(() => {
          console.log('Interval triggered for AuthNLngContainer');
          checkCookieExpiration().then((response) => {
            if (!response) {
              navigate('/logout');
            }
          });
        }, 5 * 60 * 1000);
      }
      // Cancel the interval if the expiration date is reached
      if (Date.now() > expirationDate && intervalId) {
        clearInterval(intervalId);
      }
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    return () => {
      console.log('clearing interval on AuthNLngContainer');
      if (intervalId) clearInterval(intervalId);
    };
  }, [auth.user]);
  return (
    <div className={styles.authNLngContainer}>
      {isAuthenticated ? (
        <AuthenticatedUserHeader />
      ) : (
        <NotAuthenticatedUserHeader />
      )}
      <LanguagePicker />
    </div>
  );
}
