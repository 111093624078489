import styles from './EventsFilter.module.scss';
import CustomRadioButton from '../CustomRadioButton/CustomRadioButton';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';

const CheckboxGroup = ({ selectedTypes, onChangeChecked, value }) => {
  const checked = selectedTypes.includes(value);
  console.log('checked for ', value, ' => ', checked);

  return (
    <div className={styles.checkboxGroup}>
      <CustomCheckbox
        checked={checked}
        onChangeChecked={() => onChangeChecked(value)}
        variant="filterCheckbox"
      />
      <span>{value}</span>
    </div>
  );
};
export default function EventsFilter({
  formats,
  selectedFormat,
  onFormatChange,
  categories,
  selectedCategory,
  onCategoryChange,
  types,
  selectedTypes,
  onTypesChange,
  datesOptions,
  selectedDateOpt,
  onDateOptChange,
}) {
  // const handle
  return (
    <div className={styles.eventsFilter}>
      <section>
        <h3>Format</h3>
        <div className={styles.filterOpts}>
          {formats.map((format) => (
            <CustomRadioButton
              key={format}
              label={format}
              btnValue={format}
              radioCurrentValue={selectedFormat}
              onChangeChecked={onFormatChange}
              variant="eventFilter"
            />
          ))}
        </div>
        <div className={styles.divider}></div>
      </section>
      <section>
        <h3>Category</h3>
        <div className={styles.filterOpts}>
          {categories.map((category) => (
            <CustomRadioButton
              key={category}
              label={category}
              btnValue={category}
              radioCurrentValue={selectedCategory}
              onChangeChecked={onCategoryChange}
              variant="eventFilter"
            />
          ))}
        </div>
        <div className={styles.divider}></div>
      </section>
      <section>
        <h3>Types</h3>
        <div className={`${styles.filterOpts} ${styles.types}`}>
          {types.map((type) => (
            <CheckboxGroup
              value={type}
              onChangeChecked={onTypesChange}
              key={type}
              selectedTypes={selectedTypes}
            />
          ))}
        </div>
        <div className={styles.divider}></div>
      </section>
      <section>
        <h3>Date</h3>
        <div className={styles.filterOpts}>
          {datesOptions.map((type) => (
            <CustomRadioButton
              key={type}
              label={type}
              btnValue={type}
              radioCurrentValue={selectedDateOpt}
              onChangeChecked={onDateOptChange}
              variant="eventFilter"
            />
          ))}
        </div>
        <div className={styles.divider}></div>
      </section>
    </div>
  );
}
