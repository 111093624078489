import { useState } from 'react';
import styles from './EventListCard.module.scss';
import CustomButton from '../CustomButton/CustomButton';
import cartIcon from '../../assets/cart.svg';
import { formatDate } from '../../utils/CommonUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { min } from 'moment';

// Helper function to get the starting price of an event
// When it was develped the structure of the event hasn't beed finished yet

const getStartingPrice = (event) => {
  // If it is a free event return an empty string
  if (event.ticketing.admissionType === 'free') {
    return '';
  }
  // Get sessions
  const sections = event.ticketing.sections;
  const typesArrays = sections.map((section) => section.types);
  const types = typesArrays.flat();

  if (types && types.length > 0) {
    // Get the lowest price from all the types
    const startingPrice = types.reduce((min, type) => {
      return type.price < min ? type.price : min;
    }, types[0].price);

    return `$${startingPrice}`;
  }
  return '';
};

export default function EventListCard({ event }) {
  const [showMore, setShowMore] = useState(false);
  const { title, startTime, description, featureImage } = event;
  const sharedNumber = 120; // TODO: get the number from the event object when it is ready

  const venue = event.location.venue;
  const startingPrice = getStartingPrice(event);

  const handleViewTicketsClick = (event) => {
    console.log('handleViewTicketsClick called for event => ', event);
  };

  const BtnTitle = () => {
    return (
      <div className={styles.btnTitleContainer}>
        <p>View Tickets</p>
        {startingPrice && <span>Start from {startingPrice}</span>}
        <div className={styles.imgContainer}>
          <img src={cartIcon} alt="cart icon" />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.eventListCard}>
      <div className={styles.cardContainer}>
        <div className={styles.titleContainer}>
          <img src={featureImage[0]} alt="event thumbnail" />
          <div className={styles.textContainer}>
            <h3>{title}</h3>
            <h4>{formatDate(startTime)}</h4>
            <p>{venue}</p>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <CustomButton
            title={<BtnTitle />}
            isPill={true}
            variant={'eventViewTickets'}
            clickHandler={() => {
              handleViewTicketsClick(event);
            }}
          />
          <span>{sharedNumber} People shared this event</span>
        </div>
        {showMore && (
          <p className={styles.description}>{description.content}</p>
        )}
        <button
          className={styles.toogleBtn}
          onClick={() => setShowMore(!showMore)}
        >
          <FontAwesomeIcon icon={showMore ? faChevronUp : faChevronDown} />
        </button>
      </div>
    </div>
  );
}
