import styles from './EventDashboard.module.scss';
import rightArrow from '../../assets/right-arrow.svg';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomDropdown from '../../components/CustomDropdown/CustomDropdown';
import { useState, useRef } from 'react';
import { getEventList } from '../../utils/APIUtils';
import { useQuery } from '@tanstack/react-query';
import EventListCard from '../../components/EventListCard/EventListCard';
import EventsFilter from '../../components/EventsFilter/EventsFilter';
import useOutsideClick from '../../utils/useOutsideClick';

const SORT_OPTIONS = [
  { title: 'Date', value: 'date' },
  { title: 'A-Z', value: 'a-z' },
  { title: 'Lowest Price', value: 'lowest-price' },
  { title: 'Latest', value: 'latest' },
];

const FORMATS = ['All', 'Live', 'Stream', 'Hybrid'];
const CATEGORIES = ['All', 'Concert', 'Festival', 'Theatre', 'Sports'];
const TYPES = [
  'All',
  "80's",
  'House',
  'Thechno',
  'Classical',
  'Urban',
  'Dance',
  'Hip-hop',
  'Jazz',
  'Blues',
  'Pop',
  'Metal',
  'Hardcore',
  'Future House',
];
const DATES = ['This Weekend', 'Date Range', 'Weekdays', 'All'];

export default function EventDashboard() {
  const [sortOption, setSortOption] = useState(SORT_OPTIONS[0]);
  const [showFilter, setShowFilter] = useState(false);
  const [format, setFormat] = useState(FORMATS[0]);
  const [category, setCategory] = useState(CATEGORIES[0]);
  const [types, setTypes] = useState([TYPES[1]]);
  const [date, setDate] = useState(DATES[0]);

  const filterRef = useRef(null);

  useOutsideClick(filterRef, () => setShowFilter(false));

  const {
    status,
    data: eventsData,
    error,
    isFetching,
  } = useQuery({
    queryKey: ['events'],
    queryFn: getEventList,
  });
  // TODO: ADD ERROR HANDLER FOR FETCHING DATA

  const onCheckboxChange = (value) => {
    console.log(value);
    if (types.includes(value)) {
      setTypes((prevTypes) => {
        return prevTypes.filter((type) => type !== value);
      });
    } else {
      if (value === 'All') {
        setTypes(TYPES);
      } else {
        setTypes((prevTypes) => {
          return [...prevTypes, value];
        });
      }
    }
  };

  return (
    <div className={styles.eventDashboard}>
      <div className={styles.eventDashboardContainer}>
        <div className={styles.pathContainer}>
          <a href="/">Home</a>
          <span>
            <img src={rightArrow} alt="" />
          </span>
          <a href="/events">Events</a>
        </div>
        <div className={styles.filterContainer}>
          <CustomButton
            title="Filter"
            variant={'eventFilter'}
            clickHandler={() => setShowFilter(!showFilter)}
          />
          <span>Showing all 12 results</span>
          <CustomDropdown
            optionsObj={SORT_OPTIONS}
            title="Sort By: "
            onSelect={setSortOption}
            selectedOption={sortOption}
            variant="eventSort"
          />
        </div>
        <div className={styles.eventList}>
          {showFilter && (
            <div className={styles.filtersContainer} ref={filterRef}>
              <EventsFilter
                formats={FORMATS}
                selectedFormat={format}
                onFormatChange={setFormat}
                categories={CATEGORIES}
                selectedCategory={category}
                onCategoryChange={setCategory}
                types={TYPES}
                selectedTypes={types}
                onTypesChange={onCheckboxChange}
                datesOptions={DATES}
                selectedDateOpt={date}
                onDateOptChange={setDate}
              />
            </div>
          )}
          {isFetching ? (
            <div>Loading...</div>
          ) : (
            eventsData.data.events.map((event) => {
              return <EventListCard event={event} key={event.id} />;
            })
          )}
        </div>
      </div>
    </div>
  );
}
